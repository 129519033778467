import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'

import { Color } from 'constants/assets'
import { DescriptionOutlined } from '@mui/icons-material'
import { DocumentUploadType } from 'constants/documents'
import { FC } from 'react'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileState } from 'components/common/FileAPI'
import GalleryImage from 'components/common/GalleryImage/GalleryImage'
import styles from './DocumentGalleryContent.module.sass'
import { useGalleryDocumentsContext } from 'components/pages/Gallery/_main/contexts/GalleryDocuments.context'
import { useTranslation } from 'react-i18next'

interface Props {
  /** What upload type is being handled - INPUT / OUTPUT */
  documentUploadType: DocumentUploadType
}

/**
 * Displays upload area for documents and listing of uploaded ones
 * @example <DocumentGalleryContent />
 */
export const DocumentGalleryContent: FC<Props> = ({ documentUploadType }) => {
  const { t } = useTranslation('file_upload_item')
  const { galleryDocumentsFileController, assignmentDocuments } = useGalleryDocumentsContext()

  return (
    <Stack gap={2}>

      <FileAPIUpload
        files={galleryDocumentsFileController.filesByTag[documentUploadType] ?? []}
        acceptedFileTypes={[...supportedDocumentFileTypes, ...supportedImageFileTypes]}
        disabled={false}
        showFileListing={false}
        onDelete={(fileId) => galleryDocumentsFileController.deleteFiles([fileId])}
        uploadHandler={(files) => {
          galleryDocumentsFileController.uploadFiles(files, {
            tag: documentUploadType,
            onSettled: () => assignmentDocuments.refetch()
          })
        }}
      />

      {/* Gallery image repurposed as listing */}
      <Grid container spacing={1}>
        {(galleryDocumentsFileController.filesByTag[documentUploadType] ?? []).map((file) => (
          <Grid item key={file.id} xs={12} sm={6} md={4} lg={2}>
            <GalleryImage
              className={styles.galleryImage}
              label={file.originalFilename || undefined}
              deletable
              droppable
              onDelete={() => galleryDocumentsFileController.deleteFiles([file.id])}
              onDrop={(acceptedFiles) => {
                galleryDocumentsFileController.reUploadFile(acceptedFiles[0], file.id, {
                  tag: documentUploadType,
                  onSuccess: () => assignmentDocuments.refetch()
                })
              }}
            >
              {file.state !== FileState.RUNNING &&
                <DescriptionOutlined fontSize="large" />
              }

              {file.state === FileState.RUNNING &&
                <Stack gap={1} alignItems="center">

                  <Typography variant="text-md" fontWeight={600} color="GrayText">
                    {t(`${file.action}.action_word`)}
                  </Typography>

                  <CircularProgress
                    variant={file.progress ? 'determinate' : 'indeterminate'}
                    value={file.progress}
                    size={30}
                    thickness={5}
                    sx={{
                      color: Color.GRAY_TEXT,
                      borderRadius: '50%',
                      // Background ring for unfilled progress
                      boxShadow: `inset 0 0 0px 3px ${Color.GRAY_BORDER}`
                    }}
                  />
                </Stack>
              }
            </GalleryImage>
          </Grid>
        ))}
      </Grid>

    </Stack>
  )
}
